import superagent from 'superagent'
import { getApiUrl } from './selectors'
import { unauthorizedRedirect } from '../../../ajax.js'

const has = (actions, actionName) => {
    return actions.hasOwnProperty(actionName) && typeof actions[actionName] === 'function'
}

export const RESET = 'RESET'
export const reset = () => ({ type: RESET })

export const jumpTo = (node, actions, history) => (dispatch) => {

    if(!node.metadata.isContainer && has(actions, 'onTargetNode'))
    {
        return actions.onTargetNode(node)
    }

    dispatch(reset())

    return dispatch(load(node.id, history))
}

export function load(nodeId, history) {
    return async function load(dispatch, getState) {
        const url = getApiUrl(getState(), nodeId)

        await superagent.get(url)
            .set('X-Requested-With', 'XMLHttpRequest')
            .use(unauthorizedRedirect)
            .then((res) => {
                dispatch(nodeListLoaded(res.body))
            })
            .catch(function(err) {
                console.log(err)
            })

        if(history !== undefined)
        {
            history.push(nodeId, {nodeId})
        }
    }
}

export const INIT = 'INIT'
export const init = (apiUrl, nodeId, history) => (dispatch) => {
    dispatch({
        type: INIT,
        apiUrl,
    })

    dispatch(load(nodeId))

    history.replace(nodeId, {nodeId})

    history.listen((location, action) => {
        if(action === 'POP')
        {
            dispatch(load(location.state.nodeId))
        }
    })
}

export const NODE_LIST_LOADED = 'NODE_LIST_LOADED'
export function nodeListLoaded(nodeList){
    return function nodeListLoaded(dispatch){
        dispatch({ type: NODE_LIST_LOADED, nodeList })
    }
}
