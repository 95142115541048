import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {NodeEntity} from "../../helpers/Entities/NodeEntity"

class Node extends Component
{
    static propTypes = {
        node: PropTypes.instanceOf(NodeEntity).isRequired,
        onClick: PropTypes.func.isRequired,
        current: PropTypes.bool,
        icons: PropTypes.objectOf(PropTypes.string),
    }

    render()
    {
        const {node, onClick, current = false, icons = {}} = this.props

        const item = <span><i className={`fas ${node.icon(icons)}`} aria-hidden="true"/>{node.title}</span>

        if(current)
        {
            return (<li>{item}</li>)
        }

        if(!node.metadata.isEnable)
        {
            return (
                <li className="disabled">
                    <div className="node" title={node.title}>{item}</div>
                </li>
            )
        }

        return (
            <li>
                <div className="node" onClick={onClick} title={node.title}>{item}</div>
            </li>
        )
    }
}

export default Node
