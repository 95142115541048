import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { NodeListContainer } from '.'
import { reducer, enhancer } from '../store/treeBrowser'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { treeBrowserHistory, nullBrowserHistory} from '../helpers/browserHistory/'

class TreeBrowser extends Component {

    static propTypes = {
        apiUrl: PropTypes.string.isRequired,
        nodeId: PropTypes.string,
        noDocumentLabel: PropTypes.string.isRequired,
        titleLabel: PropTypes.string.isRequired,
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
        icons: PropTypes.objectOf(PropTypes.string),
        history: PropTypes.object,
    }

    store = null
    browserHistory = new nullBrowserHistory()

    configureStore = () => {
        if(this.store === null)
        {
            this.store = createStore(reducer, enhancer)
        }

        return this.store
    }

    componentWillMount() {
        if(this.props.history !== undefined)
        {
            this.browserHistory = new treeBrowserHistory(this.props.history)
        }
    }

    render() {

        return (
            <MuiThemeProvider>
                <Provider store={ this.configureStore() }>
                    <NodeListContainer {...this.props} history={ this.browserHistory } />
                </Provider>
            </MuiThemeProvider>
        )
    }
}

export default TreeBrowser
