import React from 'react'
import ReactDOM from 'react-dom'

export const notification = {
    applyFadeOut: function(elements) {
        window.setTimeout(function() {
            elements.fadeTo(500, 0).slideUp(500, function() {
                $(this).remove();
            });
        }, 5500);
    },
    
    add: function(Component, type, message) {
        const alertElement = $("<div>")
        alertElement.css({width: '100%'})
        $(".alert-container").append(alertElement)
        
        ReactDOM.render(React.createElement(Component, { type, message }), alertElement.get(0))

        if($(".alert-persistent", alertElement).length == 0)
        {
            this.applyFadeOut(alertElement)
        }
    },
    
    success: function(Component, msg) {
        this.add(Component, 'success', msg)
    },
    
    error: function(Component, msg) {
        this.add(Component, 'danger', msg)
    }
}

$( document ).ready(function() {
    notification.applyFadeOut($(".alert:not(.alert-persistent)"))
});
