import { 
    NODE_LIST_LOADED,
    INIT,
    RESET,
} from './actions'

const defaultState = {
    apiUrl: null,
    nodeList: {
        children: [],
        current: null,
        ancestors: []
    }
}

export const treeBrowser = (state = defaultState, action) => {
    switch (action.type) {
        case INIT:
            return {
                ...state,
                apiUrl: action.apiUrl,
            }
        case RESET:
            return {
                ...state,
                nodeList: {
                    children: [],
                    current: null,
                    ancestors: []
                }
            }
        case NODE_LIST_LOADED:
            return {
                ...state,
                nodeList: {
                    ...action.nodeList
                }
            }
        default:
            return state
    }
}
