import 'bootstrap-confirmation2'

const defaultCustomOptions = {
    btnClasses: {
        cancel: 'btn-danger',
        confirm: 'btn-success',
    },
    onConfirm: () => {}
}

export const initConfirmation = (parent, customOptions) => {
    
    const options = {
        ...defaultCustomOptions,
        ...customOptions,
    }
    
    $('[data-toggle=confirmation]', parent).each(function() {
        $(this).confirmation({
            onConfirm: options.onConfirm,
            buttons: [
                {
                    class: `btn ${options.btnClasses.cancel}`,
                    iconClass: 'fa fa-times',
                    label: $(this).data('btnCancelLabel'),
                    cancel: true
                },
                {
                    class: `btn ${options.btnClasses.confirm}`,
                    iconClass: 'fa fa-check',
                    label: $(this).data('btnOkLabel'),
                },
            ],
            rootSelector: '[data-toggle=confirmation]',
            popout: true,
            singleton: true,
        })
        // Workaround for the tooltip to appear
        .attr('title', $(this).data('originalTitle'))
    })
}
