export class treeBrowserHistory
{
    constructor(browserHistory)
    {
        this.browserHistory = browserHistory
        this.listenerAdded = false
    }

    push(url, state)
    {
        this.browserHistory.push(url, state)
    }

    replace(url, state)
    {
        this.browserHistory.replace(url, state)
    }

    listen(dispatchAction)
    {
        if(this.listenerAdded === true)
        {
            return
        }

        this.browserHistory.listen(dispatchAction)
        this.listenerAdded = true
    }
}
