import { combineReducers, compose } from 'redux'
import { applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import immutableStateInvariantMiddleware from 'redux-immutable-state-invariant'
import { treeBrowser } from '../redux/treeBrowser/reducer'

export const reducer = combineReducers({
    treeBrowser,
})

const isDevelopmentEnv = process.env.NODE_ENV === 'development'
const isDevtoolsExtensionEnabled = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && isDevelopmentEnv

let composeEnhancers = compose
if(isDevtoolsExtensionEnabled)
{
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
}

export const enhancer = composeEnhancers(
    applyMiddleware(
        thunkMiddleware,
        immutableStateInvariantMiddleware()
    ),
)
