import {NodeMetadata} from "./NodeMetadata"
import {LinkData} from "./AdditionalData/LinkData"
import {TileData} from "./AdditionalData/TileData"

export class NodeEntity
{
    id
    parentId
    title
    metadata
    additionalData

    constructor(node)
    {
        this.id = node.id
        this.parentId = node.parentId
        this.title = node.title
        this.metadata = new NodeMetadata(node.metadata)
        this.additionalData = NodeEntity.createAdditionalData(node.additionalDataType, node.additionalData)
    }

    icon(icons)
    {
        return this.metadata.selectIcon(icons)
    }

    static createAdditionalData(type, additionalData)
    {
        if (type === 'link')
        {
            return new LinkData(additionalData)
        }

        if (type === 'tile')
        {
            return new TileData(additionalData)
        }

        return null
    }

    static createList(nodes)
    {
        const nodeList = []

        nodes.forEach(function(node)
        {

            nodeList.push(new NodeEntity(node))
        })

        return nodeList
    }
}
