import {NodeEntity} from "../../helpers/Entities/NodeEntity"

const getNodeList = state => state.treeBrowser.nodeList

export const getAncestors = state => NodeEntity.createList(getNodeList(state).ancestors)
export const getCurrent = state => getNodeList(state).current ? new NodeEntity(getNodeList(state).current) : undefined
export const getChildren = state => NodeEntity.createList(getNodeList(state).children)

export const getApiUrl = (state, nodeId) => {
    if(! nodeId)
    {
        return state.treeBrowser.apiUrl.replace('/:nodeId:', '')
    }
    
    return state.treeBrowser.apiUrl.replace(':nodeId:', nodeId)
}
