import React from 'react'
import { components } from '@naoned-systemes/react-select'

export function Option(props)
{
    return(
        <div title={props.data.label}>
            <components.Option {...props} />
        </div>
    )
}
