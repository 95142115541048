import { connect } from 'react-redux'
import NodeList from './NodeList.jsx'
import { init } from '../../redux/treeBrowser/actions'
import {getAncestors, getChildren, getCurrent} from '../../redux/treeBrowser/selectors'

const mapDispatchToProps = (dispatch, ownProps) => ({
    load: () => dispatch(init(ownProps.apiUrl, ownProps.nodeId, ownProps.history))
})

const mapStateToProps = state => ({
    ancestors: getAncestors(state),
    current: getCurrent(state),
    children: getChildren(state),

    dataLoaded: getCurrent(state) !== undefined,
})

export const NodeListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NodeList)
