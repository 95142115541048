import React, { Component } from 'react'

export class MultiValueContainer extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            className: null,
        }
    }

    render()
    {
        const {
            innerProps: { ref, ...restInnerProps }
        } = this.props

        const children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                updateMultiValueClassName: className => this.setState({ className })
            })
        })

        if(this.state.className !== null)
        {
            restInnerProps.className = restInnerProps.className.concat(' ', this.state.className)
        }


        return(
            <div
                ref={ref}
                {...restInnerProps}
            >
                {children}
            </div>
        )
    }
}
