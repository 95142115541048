import React, { Component } from 'react'
import AsyncSelect from '@naoned-systemes/react-select/async'
import { MenuList } from './MenuList.jsx'
import { ClearIndicator } from './ClearIndicator.jsx'
import { DropdownIndicator } from './DropdownIndicator.jsx'
import { MultiValueRemove } from './MultiValueRemove.jsx'
import { MultiValueContainer } from './MultiValueContainer.jsx'
import { Option } from './Option.jsx'
import { find } from 'lodash'
import PropTypes from 'prop-types'
import {
    removeAccents,
} from '../../helpers/string'

export class Multiselect extends Component
{
    static propTypes = {
        inputId: PropTypes.string,
        name: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
        selectedOptions: PropTypes.array.isRequired,
        noOptionsMessage: PropTypes.string.isRequired,
        loadingMessage: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
        submitAsJson: PropTypes.bool.isRequired,
    }

    constructor(props)
    {
        super(props)

        this.filter = this.filter.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.formField = this.formField.bind(this)
        this.fieldAsJson = this.fieldAsJson.bind(this)
        this.fieldAsSelect = this.fieldAsSelect.bind(this)

        const selectedOptions = props.selectedOptions
            .filter((item) => (
                find(props.options, { value: item }) !== undefined
            ))
            .map((item) => (
                {
                    value: item,
                    label: find(props.options, { value: item }).label
                }
            ))
        this.state = { selectedOptions }
    }

    filter(inputValue)
    {
        return this.props.options.filter(i =>
            removeAccents(i.label.toLowerCase()).includes(removeAccents(inputValue.toLowerCase()))
        )
    }

    loadOptions(inputValue, callback)
    {
        setTimeout(() => {
            callback(this.filter(inputValue))
        }, 1000)
    }

    handleOnChange(values)
    {
        let selectedOptions = []

        if(values)
        {
            selectedOptions = values
        }

        this.setState({ selectedOptions })
    }

    fieldAsJson(id, name)
    {
        return (
            <input
                type={'hidden'}
                id={id}
                name={name}
                value={JSON.stringify(this.state.selectedOptions.map((item) => item.value))}
                onChange={() => {}}
                style={{ visibility: "hidden", height: 0 }}
                aria-hidden={true}
            />
        )
    }

    fieldAsSelect(id, name)
    {
        return (
            <select
                id={ id }
                name={`${name}[]`}
                multiple={true}
                value={this.state.selectedOptions.map((item) => item.value)}
                onChange={() => {}}
                style={{ visibility: "hidden", height: 0 }}
                aria-hidden={true}
            >
                {Array.from(this.state.selectedOptions, (option) => (
                    <option
                        value={option.value}
                        key={option.value}
                    >
                        {option.label}
                    </option>
                ))}
            </select>
        )
    }

    formField(id, name)
    {
        if(this.props.submitAsJson === true)
        {
            return this.fieldAsJson(id, name)
        }

        return this.fieldAsSelect(id, name)
    }

    render()
    {
        const { inputId, name, loadingMessage, noOptionsMessage, placeholder } = this.props

        return (
            <React.Fragment>
                <AsyncSelect
                    inputId={ inputId }
                    cacheOptions
                    defaultOptions
                    loadOptions={this.loadOptions}
                    components={{ MenuList, ClearIndicator, DropdownIndicator, MultiValueRemove, MultiValueContainer, Option }}
                    isMulti
                    className={"enhanced-select-component"}
                    classNamePrefix={"enhanced-select"}
                    noOptionsMessage={() => noOptionsMessage}
                    loadingMessage={() => loadingMessage}
                    placeholder={placeholder}
                    onChange={values => this.handleOnChange(values)}
                    defaultValue={this.state.selectedOptions}
                />
                {
                    this.formField(inputId, name)
                }
            </React.Fragment>
        )
    }
}
