export class TileData
{
    type
    subtitle
    isVisible
    image

    constructor(additionalData)
    {
        this.type = additionalData.type
        this.subtitle = additionalData.subtitle
        this.isVisible = additionalData.isVisible
        this.image = additionalData.image
    }
}
