import React, { Component } from 'react'
import CircularProgress from 'material-ui/CircularProgress'


export class Loading extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            display: 'none',
        }
        
        this.styles = {
            loadingWrapper: {
                width: '100%',
                height: '100%',
            },
            loading: {
                position: 'absolute',
                left: 'calc(50% - 60px)',
                top: 'calc(50% - 60px)',
            },
        }
    }
    
    componentDidMount()
    {
        const { delay = 200 } = this.props
        
        this.timer = setTimeout(() => { 
            this.displayComponent() 
        }, delay)
    }
    
    componentWillUnmount()
    {
        clearTimeout(this.timer)
    }
    
    displayComponent()
    {
        this.setState({ display: 'block' })
    }
    
    render()
    {
        const { size = 120, thickness = 10 } = this.props
        
        return (
            <div style={{
                ...this.styles.loadingWrapper,
                display: this.state.display,
            }}>
                <CircularProgress style={this.styles.loading} mode="indeterminate" size={size} thickness={thickness} />
            </div>
        )
    }
}
