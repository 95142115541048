export class LinkData
{
    url
    forceOpenInNewTab

    constructor(additionalData)
    {
        this.url = additionalData.url
        this.forceOpenInNewTab = additionalData.forceOpenInNewTab
    }
}
