import React from 'react'

export function ClearIndicator(props)
{
    const {
        innerProps: { ref, ...restInnerProps }
    } = props

    return(
        <div
            ref={ref}
            className={"enhanced-select__clear-indicator"}
            {...restInnerProps}
        />
    )
}
