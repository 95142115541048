import React from 'react'
import ReactDOM from 'react-dom'
import { Multiselect } from './components/Multiselect.jsx'

export const initMultiselect = (element) => {

    const requiredAttr = ['name', 'options', 'selectedOptions', 'noOptionsMessage', 'loadingMessage', 'placeholder']
    requiredAttr.forEach((data) => {
        if(! element.dataset[data])
        {
            throw `Missing or empty ${data} in multiselect definition`
        }
    })

    const options = parseOptions(element)
    const selectedOptions = Array.from(JSON.parse(element.dataset.selectedOptions) || [])
    const {
        inputId,
        name,
        noOptionsMessage,
        loadingMessage,
        placeholder,
        submitAsJson,
    } = element.dataset

    ReactDOM.render(<Multiselect
        inputId={inputId}
        name={name}
        options={options}
        selectedOptions={selectedOptions}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
        placeholder={placeholder}
        submitAsJson={submitAsJson === 'true'}
    />, element)
}

function parseOptions(element)
{
    return Array.from(JSON.parse(element.dataset.options) || [], (value) => {
        if (typeof value !== 'object')
        {
            return {
                label: value,
                value,
            }
        }

        if (! element.dataset.optionValueKey || ! element.dataset.optionLabelKey)
        {
            throw "Given option is an object. Need to know in which keys of this object to find label and value"
        }

        return {
            label: value[element.dataset.optionLabelKey],
            value: value[element.dataset.optionValueKey],
        }
    })
}
