import { connect } from 'react-redux'
import Node from './Node.jsx'
import { jumpTo } from '../../redux/treeBrowser/actions'

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClick: () => {
        const { node, actions, history } = ownProps

        dispatch(jumpTo(node, actions, history))
    }
})

export const NodeContainer = connect(
    undefined,
    mapDispatchToProps,
)(Node)
