import React from 'react'
import {
    List,
    AutoSizer,
} from 'react-virtualized'

export function MenuList(props)
{
    const { children } = props
    const rowHeight = 30

    if(! Array.isArray(children))
    {
        return children
    }

    const rowsCount = children.length

    const rowRenderer = ({ key, index, style }) => (
        <div key={key} style={style}>{children[index]}</div>
    )

    return(
        <AutoSizer disableHeight>
            {({ width }) => (
                <List
                    className={"list"}
                    //FIX: subtract 2 to take into account border of react-select menu container
                    width={width - 2}
                    height={Math.min(200, rowHeight * rowsCount)}
                    rowHeight={rowHeight}
                    rowCount={rowsCount}
                    rowRenderer={rowRenderer}
                />
            )}
        </AutoSizer>
    )
}
