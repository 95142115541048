import React, { Component } from 'react'

export class MultiValueRemove  extends Component
{
    constructor(props)
    {
        super(props)

        this.handleOnMouseOver = this.handleOnMouseOver.bind(this)
        this.handleOnMouseOut = this.handleOnMouseOut.bind(this)
        this.handleOnMouseDown = this.handleOnMouseDown.bind(this)
    }

    handleOnMouseOver(event)
    {
        this.props.updateMultiValueClassName('hover')
    }

    handleOnMouseOut(event)
    {
        this.props.updateMultiValueClassName(null)
    }

    handleOnMouseDown(event)
    {
        this.props.updateMultiValueClassName('active')
        event.stopPropagation()
    }

    render()
    {
        const {
            innerProps: { ref, ...restInnerProps }
        } = this.props

        return(
            <div
                ref={ref}
                className={"enhanced-select__multi-value__remove"}
                {...restInnerProps}
                onMouseOver={this.handleOnMouseOver}
                onMouseOut={this.handleOnMouseOut}
                onMouseDown={this.handleOnMouseDown}
            />
        )
    }
}
