export class NodeMetadata
{
    icon
    isEnable
    isContainer

    constructor(metadata)
    {
        this.icon = metadata.icon
        this.isEnable = metadata.isEnable
        this.isContainer = metadata.isContainer
    }

    selectIcon(icons)
    {
        if(icons === undefined || !(this.icon in icons))
        {
            return 'question-circle-o'
        }

        return icons[this.icon]
    }
}
