import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {NodeContainer} from '../Node/Node'
import _ from 'lodash'
import loader from 'hoc-react-loader'
import {Loading} from '../../../loading'
import {NodeEntity} from "../../helpers/Entities/NodeEntity"

class NodeList extends Component
{

    maxAncestors = 5

    static propTypes = {
        apiUrl: PropTypes.string.isRequired,
        nodeId: PropTypes.string,
        ancestors: PropTypes.arrayOf(PropTypes.instanceOf(NodeEntity)).isRequired,
        current: PropTypes.instanceOf(NodeEntity),
        children: PropTypes.arrayOf(PropTypes.instanceOf(NodeEntity)).isRequired,
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
        titleLabel: PropTypes.string.isRequired,
        noDocumentLabel: PropTypes.string.isRequired,
        dataLoaded: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        icons: PropTypes.objectOf(PropTypes.string),
    }

    render()
    {
        const {
            actions,
            titleLabel,
            noDocumentLabel,
            children,
            ancestors,
            current,
            icons,
            history,
        } = this.props

        let elements = []
        children.forEach((node, index) => {
            elements.push(<NodeContainer
                key={index}
                node={node}
                actions={actions}
                icons={icons}
                history={history}
            />)
        })

        if(_.isEmpty(elements))
        {
            elements = <li className="no-document">{noDocumentLabel}</li>
        }

        return (
            <div className="tree-browser">
                <ul className="recap-breadcrumb clearfix">
                    {ancestors.map((node, index) => {
                        if(index !== 0 && index <= ancestors.length - (this.maxAncestors - 1))
                        {
                            if(index === ancestors.length - this.maxAncestors)
                            {
                                return <li key={index} className="concat-node">...</li>
                            }

                            return
                        }

                        return <NodeContainer key={node.id} node={node} actions={actions} history={history}/>
                    }, this)}

                    {!_.isEmpty(current) && <NodeContainer key={current.id} current={true} node={current} actions={actions} icons={icons} history={history}/>}
                </ul>

                <div className="recap-list">
                    <div id="recap-list-header">{titleLabel}</div>
                    <ul>
                        {elements}
                    </ul>
                </div>
            </div>
        )
    }
}

const LoadingIndicator = () => <Loading/>

export default loader({print: ['dataLoaded'], LoadingIndicator: LoadingIndicator})(NodeList)
