import copy from 'clipboard-copy'

export const clipboard = {
    init: function(selector, onCopy)
    {
        $(selector).on('click', (event) => {
            const textToCopy = $(event.currentTarget).data('copyClipboard')
            copy(textToCopy)
            
            if(typeof onCopy === 'function')
            {
                onCopy(event)
            }
        })
    }
}
